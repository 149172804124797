import { Component, Input } from '@angular/core';
import { ICustomerBE } from '@datalex-software-as/datalex-client';
import { IgxInputGroupType, IgxInputGroupComponent, IgxLabelDirective, IgxInputDirective } from '@infragistics/igniteui-angular';
import { FormsModule } from '@angular/forms';


@Component({
    selector: 'app-customer-statestic-info',
    templateUrl: './customer-statestic-info.component.html',
    styleUrls: ['./customer-statestic-info.component.scss'],
    standalone: true,
    imports: [IgxInputGroupComponent, IgxLabelDirective, FormsModule, IgxInputDirective]
})
export class CustomerStatesticInfoComponent {


  @Input() customer!: ICustomerBE;
  @Input() datalexServiceURL = "";
  @Input() datalexWebURL!: string | null;
  theme: IgxInputGroupType = "box"


  ngOnInit() {


  }
}
