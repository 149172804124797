<div>
    <div class="c_input_row flex-row">
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountCases" class="igx-label-adjustment">Saker</label>
            <input #input igxInput name="customer.CountCases" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountCases" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountContacts" class="igx-label-adjustment">Kontakter</label>
            <input #input igxInput name="customer.CountContacts" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountContacts" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountUsers" class="igx-label-adjustment">Brukere</label>
            <input #input igxInput name="customer.CountUsers" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountUsers" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountActiveUsers" class="igx-label-adjustment">Aktive brukere</label>
            <input #input igxInput name="customer.CountActiveUsers" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountActiveUsers" />
        </igx-input-group>
    </div>
    <div class="c_input_row flex-row">
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountDocuments" class="igx-label-adjustment">Dokumenter</label>
            <input #input igxInput name="customer.CountDocuments" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountDocuments" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountMainAccounts" class="igx-label-adjustment">Hovedregnskap</label>
            <input #input igxInput name="customer.CountMainAccounts" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountMainAccounts" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountOtherAccounts" class="igx-label-adjustment">Andre
                regnskap</label>
            <input #input igxInput name="customer.CountOtherAccounts" autocomplete="off" class="igx-select-adjustment"
                type="number" [disabled]="true" [(ngModel)]="customer.CountOtherAccounts" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountBankruptcyEstates" class="igx-label-adjustment">BO</label>
            <input #input igxInput name="customer.CountBankruptcyEstates" autocomplete="off"
                class="igx-select-adjustment" type="number" [disabled]="true"
                [(ngModel)]="customer.CountBankruptcyEstates" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.CountPropertyAssignments" class="igx-label-adjustment">Eiendomsoppdrag</label>
            <input #input igxInput name="customer.CountPropertyAssignments" autocomplete="off"
                class="igx-select-adjustment" type="number" [disabled]="true"
                [(ngModel)]="customer.CountPropertyAssignments" />
        </igx-input-group>

    </div>

    <div class="url-group flex-col">
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.DatalexServiceURL" class="igx-label-adjustment">DatalexServiceURL</label>
            <input #input igxInput name="customer.DatalexServiceURL" autocomplete="off" class="igx-select-adjustment"
                type="text" [disabled]="true" [(ngModel)]="datalexServiceURL" />
        </igx-input-group>
        <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
            <label igxLabel for="customer.datalexWebURL" class="igx-label-adjustment">DatalexWebURL</label>
            <input #input igxInput name="customer.datalexWebURL" autocomplete="off" class="igx-select-adjustment"
                type="text" [disabled]="true" [(ngModel)]="datalexWebURL" />
        </igx-input-group>

    </div>
</div>