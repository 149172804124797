<!-- <div class="otp-input-container">
  <input type="tel" #inputField [class.error-state]="isErrorState" id="otp-1" maxlength="1" [(ngModel)]="otp[0]"
    (input)="onInput($event, 0)" (keydown)="onKeyDown($event, 0)">
  <input type="tel" #inputField [class.error-state]="isErrorState" id="otp-2" maxlength="1" [(ngModel)]="otp[1]"
    (input)="onInput($event, 1)" (keydown)="onKeyDown($event, 1)">
  <input type="tel" #inputField [class.error-state]="isErrorState" id="otp-3" maxlength="1" [(ngModel)]="otp[2]"
    (input)="onInput($event, 2)" (keydown)="onKeyDown($event, 2)">
  <input type="tel" #inputField [class.error-state]="isErrorState" id="otp-4" maxlength="1" [(ngModel)]="otp[3]"
    (input)="onInput($event, 3)" (keydown)="onKeyDown($event, 3)">
</div> -->
<div class="otp-input-container">
@for (item of otp; track $index) {
  <input type="tel" #inputField [class.error-state]="isErrorState" [id]="'otp-' + ($index +1)" maxlength="1" [(ngModel)]="otp[$index]"
  (input)="onInput($event, $index)" (keydown)="onKeyDown($event, $index)">
}
</div>