@if(showNavBar){
<app-navigation-bar />
}
<activity-log-overlay></activity-log-overlay>
<modal-hub></modal-hub>
<div [ngClass]="{'content': !isPdfViewer}">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
<app-information-overlay></app-information-overlay>

@if(showToolBar){
<app-toolbar />

}

<igx-dialog #versionDialog title="Datalex server er utdatert" [closeOnOutsideSelect]="false">

  <div>
    <p>Nåværende versjon: {{currentServerVersion}}</p>
    <p>Påkrevd versjon: {{requiredServerVersion}}</p>
    <br>
    <p>Kontakt Datalex Software for oppdatering av deres server.</p>
  </div>

  <div igxDialogActions>
    <button igxButton (click)="logOut()">Logg ut</button>
  </div>
</igx-dialog>