<div class="document-type">
  <div class="flex-row space-between title-row">
    <h6 class="component-title">Dokumenttypesøk</h6>
    <i class="material-icons pointer" (click)="destroy()">close</i>
  </div>
  <div class="grid-button-row m-top">
    <app-button-row-button #rb1 [icon]="'add'" [label]="'Ny type'" [enabled]="true"
      (click)="toggleActionPanel(actionPanel1)">
    </app-button-row-button>
  </div>
  <div #actionPanel1 id="ap1" class="grid-button-row-action-panel grap-hidden w100">
    <div class="flex-col w100">
      <form class="flex-row space-between padding-8" [formGroup]="documentTypeForm" (submit)="onSubmit(actionPanel1)">
        <div class="flex-row w100 space-between">
          <div class="flex-row input-group">
            <div class="spacer form-input">
              <label for="name">
                <input type="text" id="name" placeholder="Dokumenttype:" formControlName="Name" />
                <span>Dokumenttype:</span>
              </label>
            </div>
            <div class="flex-row">
              <label for="isTemplate" class="spacer">Maltype? </label>
              <input id="isTemplate" type="checkbox" formControlName="IsTemplateType" />
            </div>
          </div>
          <div class="flex-row">
            <button class="spacer" type="submit" igxButton="contained" [style.color]="'white'" [style.background]="
                    documentTypeForm.value.DeleteAllowed &&
                    documentTypeForm.value.Name
                      ? '#AEC965'
                      : '#E0E0E0'
                  " igxRipple="white" [disabled]="
                    documentTypeForm.value.Name &&
                    !documentTypeForm.value.DeleteAllowed &&
                    !documentTypeForm.value.IsNew
                  ">
              lagre
            </button>

            <button type="button" igxButton="contained" [style.color]="'white'" igxRipple="white"
              (click)="delete(actionPanel1)" [disabled]="
                    isSelected ||
                    !documentTypeForm.value.DeleteAllowed ||
                    documentTypeForm.value.IsNew
                  ">
              SLETT
            </button>
          </div>
        </div>
      </form>
      <div #warningDisplay class="grap-hidden warningText">
        {{ warningText }}
      </div>
    </div>
  </div>
  <div class="document-type-list">
    <igx-grid #tGrid [data]="types" (selected)="getData($event, actionPanel1)" [rowSelection]="'single'"
      [hideRowSelectors]="true" [allowFiltering]="true" height="450px" style="--ig-size: var(--ig-size-small)"
      class="remove-shadows">
      <igx-column field="Name" header="Dokumenttype" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="defaultFilterTemplate"></igx-column>
      <igx-column field="IsTemplateType" header="Maltype" [sortable]="true" [dataType]="'boolean'" [width]="'80px'"
        [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    </igx-grid>
  </div>
</div>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <div class="filterInputWrapper">
    <input #input tabindex="0" class="filterInput" autocomplete="off" style="text-align: left"
      [value]="gms.getFilterValue(column)" (input)="gms.onInput(tGrid, input, column)" (click)="gms.onClick($event)"
      (keydown)="gms.onKeyDown($event)" />
  </div>
</ng-template>