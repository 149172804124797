<label class="custom-file-upload">
  <input type="file" (change)="fileChangeEvent($event)" />
  Velg bilde
</label>
<label class="custom-file-upload-filename">{{ filename }}</label>
<!--
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
    (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
</image-cropper>
-->

<button *ngIf="croppedImage !== ''" class="spacer" igxButton="contained" [style.color]="'white'"
  [style.background]="'#575757'" (click)="saveImageCaller()">Lagre</button>