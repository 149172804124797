import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'otp-input',
  templateUrl: './otpinput.component.html',
  styleUrl: './otpinput.component.scss',
  standalone: true,
  imports: [FormsModule]
})
export class OTPInputComponent implements AfterViewInit {
  @Output() completed = new EventEmitter<string>();
  @Output() enterPressed = new EventEmitter<void>();  // Emit when the last input is filled and "enter" should be triggered
  @ViewChildren('inputField') inputFields!: QueryList<ElementRef<HTMLInputElement>>;
  isErrorState: boolean = false;


  @Input() codeLength!: number;
  otp!: string[];

  ngAfterViewInit() {
    this.otp = [];
    for (let i = 0; i < this.codeLength; i++) {
      this.otp.push('');

    }
    setTimeout(() => this.focusField(0), 0);
  }

  onInput(event: any, index: number) {
    const value = event.target.value.slice(-1); // Taking only the last character
    this.otp[index] = value;
    event.target.value = value; // Resetting the value to ensure only one character

    if (value) {
      if (index < this.codeLength - 1) {
        this.focusField(index + 1, true); // Move to the next field and select its content
      } else if (index === this.codeLength - 1) {
        this.validateFinalInput(); // Validate the last input
        if (this.otp.join('').length === this.codeLength) {
          this.enterPressed.emit(); // Emit the 'enterPressed' event when the last field is filled
        }
      }
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    const invalidKeys = ['e', 'E', '+', '-']; // Array of keys to block
    if (invalidKeys.includes(event.key)) {
      event.preventDefault(); // Stop 'e', 'E', '+', '-' from being input
    }

    switch (event.key) {
      case "ArrowLeft":
        if (index > 0) {
          this.focusField(index - 1, true);
        }
        event.preventDefault(); // Always prevent default to avoid losing selection
        break;
      case "ArrowRight":
        if (index < 3) { // Allows moving to the next field regardless if it's filled
          this.focusField(index + 1, true);
        }
        event.preventDefault(); // Always prevent default to avoid losing selection
        break;
      case "ArrowUp":
      case "ArrowDown":
        this.adjustNumber(event.key, index);
        event.preventDefault(); // Prevent default to adjust number without changing focus
        break;
      case "Backspace":
        if (!this.otp[index] && index > 0) {
          this.focusField(index - 1, true);
        }
        break;
    }
  }

  adjustNumber(key: string, index: number) {
    let currentValue = this.otp[index] ? parseInt(this.otp[index], 10) : 0;
    if (key === "ArrowUp") {
      currentValue = (currentValue + 1) % 10;
    } else if (key === "ArrowDown") {
      currentValue = (currentValue - 1 + 10) % 10;
    }
    this.otp[index] = currentValue.toString();
    const inputElement = this.inputFields.toArray()[index].nativeElement;
    inputElement.value = this.otp[index];
    inputElement.select();
  }

  focusField(index: number, selectText: boolean = true) {
    const input = this.inputFields.toArray()[index].nativeElement;
    input.focus();
    if (selectText) {
      setTimeout(() => {
        input.select();
      }, 10);
    }
  }

  validateFinalInput() {
    if (this.otp.join('').length === this.codeLength) {
      this.completed.emit(this.otp.join(''));
    } else {
      this.setErrorState(true);
    }
  }

  resetOtp() {
    this.otp.fill('');
    this.focusField(0);
    this.setErrorState(false);
  }

  setErrorState(isError: boolean) {
    this.isErrorState = isError;
  }
}



