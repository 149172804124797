import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConnectedPositioningStrategy, HorizontalAlignment, IgxNavigationDrawerComponent, NoOpScrollStrategy, VerticalAlignment, IgxNavbarComponent, IgxNavbarTitleDirective, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxIconComponent, IgxDropDownComponent, IgxCardComponent, IgxCardHeaderComponent, IgxCardHeaderTitleDirective, IgxCardHeaderSubtitleDirective, IgxCardContentDirective, IgxOverlayOutletDirective, IgxBadgeComponent, IgxNavDrawerTemplateDirective, IgxNavDrawerItemDirective, IgxRippleDirective } from '@infragistics/igniteui-angular';
import { Activity } from 'src/app/classes/Activity/Actvity';
import { DatalexClient, IDocumentSharePointExtendedBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { CookieProvider } from 'src/app/util/Cookies';
import { ModalId } from 'src/app/util/ModalTypeIdEnum';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { environment } from 'src/environments/environment';
import { ActivityLogService } from '../activity-log-overlay/activity-log.service';
import { ModalHubAccessService } from '../modal-hub/access.service';
import { Subject, Subscription } from 'rxjs';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { DeviceService } from 'src/app/services/device.service';
import { DocumentCheckoutService } from '../document-checkout/document-checkout.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { MenuItemsComponent } from '../menu-items/menu-items.component';
import { DocumentCheckoutComponent } from '../document-checkout/document-checkout.component';
import { NgStyle, NgIf, NgClass, NgFor } from '@angular/common';
import { TaskIdEnum, TaskSchedulerService } from 'src/app/services/task-scheduler.service';
import { DocumentSearchDirective } from 'src/app/directives/document-search.directive';
import { FirebaseService } from 'src/app/services/firebase.service';


interface INavigationProperties {
  route: string
  label: string
  icon: string
  drawerOnly?: boolean
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  standalone: true,
  imports: [NgStyle, IgxNavbarComponent, IgxNavbarTitleDirective, NgIf, DocumentCheckoutComponent, NgClass, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxIconComponent, IgxDropDownComponent, IgxCardComponent, IgxCardHeaderComponent, IgxCardHeaderTitleDirective, IgxCardHeaderSubtitleDirective, IgxCardContentDirective, MenuItemsComponent, IgxOverlayOutletDirective, BreadcrumbComponent, IgxBadgeComponent, IgxNavigationDrawerComponent, IgxNavDrawerTemplateDirective, IgxNavDrawerItemDirective, IgxRippleDirective, NgFor, DocumentSearchDirective]
})
export class NavigationBarComponent implements OnInit, AfterViewInit {

  href: string;
  public showMenu = false;
  isPdfViewer = window.location.href.includes('pdfviewer');
  env = environment;
  appV = this.env.appVersion;

  browserType!: string;
  deviceType: string;

  private subscriptions: Subscription = new Subscription();

  screenWidth!: number;
  screenHeight!: number;



  deviceOrientation!: string;
  isLoggedInSharepoint = false;

  userRightsLoaded!: boolean
  noContactAccess: boolean = true;
  noCaseAccess: boolean = true;
  isLoginPage: boolean = false;

  constructor(
    private hostRef: ElementRef<HTMLElement>,
    public dlxMsal: DatalexMsalService,
    public als: ActivityLogService,
    public sys: SystemCacheService,
    private cookie: CookieProvider,
    private router: Router,
    public screen: ScreenDimensionService,
    private dlxClient: DatalexClient,
    private modalAccess: ModalHubAccessService,
    public rights: UserRightsProviderService,
    private taskScheduler: TaskSchedulerService,
    private fb: FirebaseService,
    private tasks: TaskSchedulerService,
    public deviceService: DeviceService, private cdr: ChangeDetectorRef, public dcs: DocumentCheckoutService) {

    this.browserType = this.deviceService.getBrowserType();
    this.deviceType = this.deviceService.getDeviceType();
    this.href = window.location.href;

    router.events.subscribe((navEvent) => {
      if (navEvent instanceof NavigationEnd) {
        this.setActiveRoute(navEvent.url)
        this.visible = true;

        if (navEvent.url === '/' || navEvent.url === '/login') {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }

        if (navEvent.url.includes('/pdfviewer/') || navEvent.url.includes('standalone')) {
          this.visible = false;
        }
      }
    });

  }

  private readonly _destorying$ = new Subject<void>();

  msconnected = false;

  @ViewChild(IgxNavigationDrawerComponent, { static: true })
  public drawer!: IgxNavigationDrawerComponent;

  public activeRoute: string = '';
  public activeRouteLabel: string = '';
  public visible: boolean = true;
  public profilePicture = '../../../../assets/images/avatar.png';
  @ViewChild('appVersion', { static: false }) appVersion!: ElementRef;

  public navigationProperties: INavigationProperties[] = [
    { route: 'shortlist', label: 'Min Saksliste', icon: 'list', drawerOnly: false },
    { route: 'findcase', label: 'Saker', icon: 'search', drawerOnly: false },
    { route: 'findcontact', label: 'Kontakter', icon: 'person_search', drawerOnly: false },
    // { route: 'help/home', label: 'Hjelp', icon: 'help', drawerOnly: true },
    // { route: 'settings', label: 'Instillinger', icon: 'manufacturing', drawerOnly: false },
    // { route: '', label: 'Logg ut', icon: 'logout', drawerOnly: true },
  ];

  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom
    }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  checkedOutDocuments: IDocumentSharePointExtendedBE[] = [];


  canOpenDocumentSearch = false;
  canOpenRegistryEditors = false;

  ngOnInit(): void {

    this.subscriptions.add(
      this.deviceService.deviceOrientation$.subscribe((orientation) => {
        this.deviceOrientation = orientation;
      })
    );

    this.subscriptions.add(
      this.deviceService.screenSize$.subscribe((size) => {
        this.screenWidth = size.width;
        this.screenHeight = size.height;
      })
    );
    this.subscriptions.add(this.dlxMsal.isLoggedInToSharePoint$.subscribe({
      next: value => {
        if (value === null) return
        this.isLoggedInSharepoint = value.isLoggedIn;
      }
    }
    ));

    try {
      this.hostRef.nativeElement.style.opacity = "1";
      this.userRightsLoaded = !!this.sys.userRights;
      const contactAcces = this.rights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase();
      if (contactAcces === UserRightTypeEnum.NOT_LOADED) {
        throw new Error("User rights not loaded");
      }
      this.noContactAccess = contactAcces === UserRightTypeEnum.NONE;

      const caseAccess = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId.toUpperCase();
      if (caseAccess === UserRightTypeEnum.NOT_LOADED) {
        throw new Error("User rights not loaded");
      }
      this.noCaseAccess = caseAccess === UserRightTypeEnum.NONE;
      this.checkDocumentAccess();
      this.checkRegisterAccess()

      // this.cdr.detectChanges();
    } catch (error) {
      this.sys.isReady.subscribe({
        next: () => {

          this.hostRef.nativeElement.style.opacity = "1";
          this.userRightsLoaded = !!this.sys.userRights;
          const contactAcces = this.rights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase();
          if (contactAcces === UserRightTypeEnum.NOT_LOADED) {
            throw new Error("User rights not loaded");
          }
          this.noContactAccess = contactAcces === UserRightTypeEnum.NONE;

          const access = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId.toUpperCase();
          this.noCaseAccess = access === UserRightTypeEnum.NONE;
          this.checkDocumentAccess();
          this.checkRegisterAccess()
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.appVersion) {
      this.appVersion.nativeElement.textContent = "Web versjon: " + environment.appVersion;
    }

    setTimeout(() => {
      this.isPdfViewer = !!document.getElementById("pdfviewer");

    }, 1)

  }

  checkDocumentAccess() {
    const accountDocumentAccessLevel = this.rights.checkAccess(this.rights.UserAreaEnum.ACCOUNT_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase();
    const caseDocumentAccessLevel = this.rights.checkAccess(this.rights.UserAreaEnum.CASE_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase();
    const contactDocumentAccessLevel = this.rights.checkAccess(this.rights.UserAreaEnum.CONTACT_DOCUMENTS).accessId.toUpperCase() === UserRightTypeEnum.NONE.toUpperCase();

    this.canOpenDocumentSearch = !accountDocumentAccessLevel || !caseDocumentAccessLevel || !contactDocumentAccessLevel;
  }

  checkRegisterAccess() {
    const registryAccess = this.rights.checkAccess(this.rights.UserAreaEnum.REGISTERS).accessId.toUpperCase() !== UserRightTypeEnum.NONE.toUpperCase()
    this.canOpenRegistryEditors = registryAccess;
  }


  setActiveRoute(route: string) {
    let pageRoute = this.navigationProperties.find(item => item.route === route.replace("/", ""))?.label;
    if (pageRoute === "Logg ut") pageRoute = 'Logg inn';
    if (route.includes('/sak/')) pageRoute = 'Sak';
    if (route.includes('/kontakt/')) pageRoute = 'Kontakt';
    if (!pageRoute) pageRoute = '';

    this.activeRoute = route.replace("/", "");
    this.activeRouteLabel = pageRoute;
  }

  navigate(item: INavigationProperties) {
    if (this.router.url.replace("/", "") === item.route) return

    if (item.route === '') {
      this.logOut();

      if (this.drawer.isOpen) this.drawer.close();
      return
    }

    this.setActiveRoute(item.label);
    this.router.navigate([item.route]);
    if (this.drawer.isOpen) {
      this.drawer.close();
    }
  }

  mNav(route: string) {
    this.router.navigate([route]);

  }

  openProfilePictureModal() {
    this.modalAccess.openModal({
      type: ModalId.ProfilePicture,
    });
  }

  toolbarNavigation({ route, label }: { route: string, label: string }) {
    if (this.router.url.replace("/", "") === route) return

    if (route === '') {
      this.logOut();

      if (this.drawer.isOpen) this.drawer.close();
      return
    }

    this.setActiveRoute(label);
    this.router.navigate([route]);
    if (this.drawer.isOpen) {
      this.drawer.close();
    }
  }


  logOut() {
    // if (this.dlxMsal.auth) {
    //   this.dlxMsal.signOut();
    // }
    this.dlxClient.RemoveServerLoginInfo().subscribe({
      complete: () => {
        this.dlxClient.token = this.dlxClient.webURL = "";
        this.taskScheduler.stop();
        this.sys.clearCache();
        this.cookie.delete(['userid', 'weburl', 'token'])
        this.router.navigate(['']);
      }
    })
  }

  showCompleteVersions() {
    if (this.vCounter === 9) {
      this.vCounter = 1;
      this.dbVersion = this.serverVersion = "";

    }
    if (this.vCounter === 7) {
      this.dlxClient.GetSystemSettings().subscribe({
        next: (settings) => {
          this.dbVersion = settings.CurrentDBVersion
          this.serverVersion = settings.CurrentServerVersion
        }
      })
    }
    this.vCounter++;
  }

  setNotificationBackground(activity: Activity) {
    const { type } = activity;
    switch (type) {
      case 'warning':
        return '#ffd000'

      case 'failure':
        return '#ff9d9d'

      default:
        return '#99fbb2'
    }
  }

  initMsalAuth() {
    if (this.sys.microsoftGraphClientConfig) {
      this.dlxMsal.initialize({ config: this.sys.microsoftGraphClientConfig, userInitiated: true });
    }
    this.cdr.detectChanges();
  }

  maslLogout() {
    this.dlxMsal.signOut();
    this.cdr.detectChanges();
  }

  vCounter: number = 0;
  dbVersion: string = "";
  serverVersion: string = "";

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.showBadge = !this.showBadge;

  }

  showBadge = true;

  closeMenu() {
    this.showMenu = false;
    this.showBadge = true;
  }

  openMenu() {
    this.showBadge = false;
    this.showMenu = true;
  }

}