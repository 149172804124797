import { Route } from '@angular/router'
import { inject } from '@angular/core';
import { DatalexClient } from '@datalex-software-as/datalex-client';


function canActivateGuard() {
  const auth = inject(DatalexClient);
  return !!auth.token && !!auth.webURL
}
const R_HourTypeMainGroups: Route = {
  path: 'hourgroups',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./hour-registration-registry/hour-registration-main-group-editor/hour-registration-main-group-editor.component').then(c => c.HourRegistrationMainGroupEditorComponent),
  data: {
    title: "Timehovedgrupper",
    route: 'hourgroups',
    label: 'Timehovedgrupper',
    icon: 'hourglass',
  }
}
const R_HourTypes: Route = {
  path: 'hourtypes',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./hour-registration-registry/hour-registration-type-editor/hour-registration-type-editor.component').then(c => c.HourRegistrationTypeEditorComponent),
  data: {
    title: "Timetyper",
    route: 'hourtypes',
    label: 'Timetyper',
    icon: 'hourglass',
  }
}
const R_CostMainGroups: Route = {
  path: 'costgroups',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./hour-registration-registry/cost-main-group-editor/cost-main-group-editor.component').then(c => c.CostMainGroupEditorComponent),
  data: {
    title: "Omkostningsgrupper",
    route: 'costgroups',
    label: 'Omkostningsgrupper',
    icon: 'hourglass',
  }
}
const R_CostTypes: Route = {
  path: 'costtypes',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./hour-registration-registry/cost-type-editor/cost-type-editor.component').then(c => c.CostTypeEditorComponent),
  data: {
    title: "Omkostningstyper",
    route: 'costtypes',
    label: 'Omkostningstyper',
    icon: 'hourglass',
  }
}



const hourRegRegistry: Route = {
  path: 'hour-registration-registry',
  children: [
    R_HourTypeMainGroups,
    // R_HourTypes,
    R_CostMainGroups,
    // R_CostTypes
  ],
  data: {
    title: "Timeregistrering",
    route: 'hour-registration-registry',
    label: 'Timeregistrering',
    icon: 'hourglass',
  },
  loadComponent: () => import('./hour-registration-registry/hour-registration-registry.component').then(c => c.HourRegistrationRegistryComponent),
}

export const registryCenter: Route = {
  path: 'registry-data',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./registry-center.component').then(c => c.RegistryCenterComponent),
  children: [
    hourRegRegistry,

  ]
}