import { Route } from '@angular/router'
import { canActivateGuard } from '../../../app-routing.module'

const R_MoneyLaunderingStatus: Route = {
  path: 'money-laundering-status',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./case-reports/money-laundering/money-laundering-status/money-laundering-status.component').then(c => c.MoneyLaunderingStatusComponent),
  data: {
    title: "Status hvitvasking",
    route: 'money-laundering-status',
    label: 'Status hvitvasking',
    icon: '',
  }
}


const caseReports: Route = {
    path: 'case-reports',
    children: [
      R_MoneyLaunderingStatus
    ],
    data: {
      title: "Saksrapporter",
      route: 'case-reports',
      label: 'Saksrapporter',
      icon: 'hourglass',
    },
    loadComponent: () => import('./case-reports/case-reports.component').then(c => c.CaseReportsComponent),
}
  
  
  export const reportCenter: Route = {
    path: 'reports',
    canActivate: [canActivateGuard],
    loadComponent: () => import('./report-center.component').then(c => c.ReportCenterComponent),
    children: [
        caseReports,
    ]
  }