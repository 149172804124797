import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IUtilTraceMessagesBE } from '@datalex-software-as/datalex-client';
import { IGridCellEventArgs, IgxInputGroupType, IgxGridComponent, IgxColumnComponent, IgxGridFooterComponent, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../../../UI/grid-item-count/grid-item-count.component';

@Component({
    selector: 'app-customer-messages',
    templateUrl: './customer-messages.component.html',
    styleUrls: ['./customer-messages.component.scss'],
    standalone: true,
    imports: [IgxGridComponent, IgxColumnComponent, IgxGridFooterComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent]
})
export class CustomerMessagesComponent implements OnDestroy {

  @Input() messages: IUtilTraceMessagesBE[] = [];
  @Input() selected!: (arg: any) => void;
  @Output() openMessage = new EventEmitter<IUtilTraceMessagesBE>();

  isMobile!: boolean;

  private subscriptions = new Subscription();

  constructor(public screenSize: ScreenSizeService) {
    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  theme: IgxInputGroupType = "box";

  handleSelection(e: IGridCellEventArgs) {
    this.openMessage.emit(e.cell.row.data);
    e.cell.grid.clearCellSelection();

  }

  public dateTimeOptions = {
    format: 'dd.MM.Y, HH:mm'
  };


}
