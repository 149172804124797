<div class="clientInfo">
  <igx-input-group class="div1 readOnly" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.Name">Navn</label>
    <input igxInput name="customer.Name" autocomplete="off" type="string" [(ngModel)]="name" />
  </igx-input-group>
  <igx-input-group class="div2 readOnly" [type]="theme" style="width: 150px;"
    [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.MaxUsers">Max brukere</label>
    <input igxInput name="customer.MaxUsers" autocomplete="off" type="number" [readOnly]="true"
      [(ngModel)]="maxUsers" />
  </igx-input-group>
  <igx-input-group class="div3 readOnly" [type]="theme" style="width: 150px;"
    [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.EndDate">Stopp dato</label>
    <input igxInput name="customer.EndDate" autocomplete="off" type="date" [readOnly]="true" [(ngModel)]="endDate" />
  </igx-input-group>
  <igx-input-group class="div4 readOnly" [type]="theme" style="width: 170px;"
    [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.LastActivationDate">Siste aktivering</label>
    <input igxInput name="customer.LastActivationDate" autocomplete="off" class="readOnly" type="string"
      [readOnly]="true" [(ngModel)]="lastActivation" />
  </igx-input-group>

  <igx-input-group class="div5" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
    <input #caseInput igxInput name="customer.case" autocomplete="off" (keypress)="caseLookup($event, caseInput)"
      class=" search-input" type="text" [(ngModel)]="customerCase" (ngModelChange)="checkType($event)"
      [igxTooltipTarget]="searchCaseRef" />
    <label igxLabel for="customer.case">Sak</label>
    <igx-suffix (click)="caseLookup($event, caseInput)">
      <i translate="no" class="material-symbols-outlined">search</i>
    </igx-suffix>
  </igx-input-group>
  <pre class="tooltip" #searchCaseRef="tooltip" igxTooltip>
        {{"Dette er en test"}}
    </pre>
  <igx-input-group class="div6 readOnly" [type]="theme" style="width: 150px;"
    [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.ShortName">Kort Navn</label>
    <input #input igxInput name="customer.ShortName" autocomplete="off" type="" [readOnly]="true"
      [(ngModel)]="shortName" />
  </igx-input-group>
  <igx-input-group class="div7 readOnly" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
    <label igxLabel for="customer.WebAppURL">WebAppURL</label>
    <input #input igxInput name="customer.WebAppURL" autocomplete="off" type="text" [readOnly]="true"
      [(ngModel)]="webAppURL" />
  </igx-input-group>
  <!-- <div class="div8">
        <button type="button" igxButton="raised"  [style.color]="'white'" igxRipple="white"
            [style.background]="customer.CaseId && canOpenCase ? '#AEC965' : '#6D767E'"
            [disabled]="customer.CaseId === null && !canOpenCase" (click)="openCase(customer.CaseId)">
            Åpne sak
        </button>
    </div>
    <div class="div9">
        <button type="button" igxButton="raised"  [style.color]="'white'" igxRipple="white" (click)="getData()">
            Hent data
        </button>
    </div>
    <div class="div10">
        <button type="button" igxButton="raised"  [style.color]="'white'" [disabled]="updated"
            [style.background]="updated ? '#6D767E' : '#AEC965'" igxRipple="white" (click)="save()">
            Lagre
        </button>
    </div> -->
</div>

<igx-tabs class="cTabs">
  <igx-tab-item>
    <igx-tab-header>
      <span igxTabHeaderLabel>Meldinger</span>
    </igx-tab-header>
    <igx-tab-content class="flex-row ">
      <app-customer-messages [messages]="customerMessages" (openMessage)="openMessage($event)"></app-customer-messages>
    </igx-tab-content>
  </igx-tab-item>

  <igx-tab-item>
    <igx-tab-header>
      <span igxTabHeaderLabel>Brukere</span>
    </igx-tab-header>
    <igx-tab-content class="flex-row ">
      <app-customer-users [users]="customerUsers"></app-customer-users>
    </igx-tab-content>
  </igx-tab-item>
  <igx-tab-item>
    <igx-tab-header>
      <span igxTabHeaderLabel>Statestikk/Info</span>
    </igx-tab-header>

    <igx-tab-content class="statistics-wrapper flex-col ">
      <app-customer-statestic-info [customer]="customer" [datalexServiceURL]="datalexServiceURL"
        [datalexWebURL]="webAppURL"></app-customer-statestic-info>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>


<div #searchPopup class="case-search-popup flex-col" *ngIf=" searchedCases">
  <div class="flex-row space-between title_row">
    <app-title value="Sak:"></app-title>
    <i class="material-icons" (click)="close()">close</i>
  </div>
  <div class="popup-wrapper flex-col">
    <div class="case-search-wrapper flex-row">
      <div class="case-search-inputs flex-row ">
        <div class="case-contact-info-wrapper">
          <h2>Saks / kontakt info</h2>
          <div class="flex-row">
            <igx-input-group [type]="theme" style="width: 150px;" [style.--ig-size]="'var(--ig-size-small)'">
              <label igxLabel for="customer.nameInitials">Navn / Initialer</label>
              <input #input igxInput name="customer.nameInitials" autocomplete="off" type=""
                [(ngModel)]="adv.nameInitials" />
            </igx-input-group>

            <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
              <label igxLabel for="customer.caseTitle">Tittel / Beskrivelse</label>
              <input #input igxInput name="customer.caseTitle" autocomplete="off" type="text"
                [(ngModel)]="adv.titleDesc" />
            </igx-input-group>


            <igx-input-group [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
              <label igxLabel for="customer.select">Ansvarlig / Behandler</label>
              <select #input igxInput name="customer.select" [(ngModel)]="adv.sasb">
                <option *ngFor="let item of saSbDataSource" [value]="item.Id">
                  {{item.ContactName}}
                </option>
              </select>
            </igx-input-group>
          </div>
        </div>

        <div class="case-status-wrapper">
          <h2> Sak status</h2>
          <div class="case-status-checkbox flex-col">
            <div class="flex-row space-between">
              <div>
                <input type="checkbox" name="active" [(ngModel)]="adv.active">
                <label for="active">Aktiv</label>
              </div>
              <div>
                <input type="checkbox" name="restricted" [(ngModel)]="adv.restricted">
                <label for="restricted">Sperret</label>
              </div>
            </div>
            <div class="flex-row space-between">
              <div>
                <input type="checkbox" name="closed" [(ngModel)]="adv.closed">
                <label for="closed">Avsluttet</label>
              </div>
              <div>
                <input type="checkbox" name="internal" [(ngModel)]="adv.internal">
                <label for="internal">Interne</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="case-status-buttons flex-col">
        <button class="find-case-button" id="submit" igxButton="contained" [style.color]="'white'"
          [style.background]="'#AEC965'" igxRipple="white" (click)="search()">SØK</button>
        <button class="find-case-button" igxButton="contained" [style.color]="'white'" [style.background]="'#575757'"
          igxRipple="white" (click)="onReset()">NULLSTILL</button>
      </div>
    </div>

    <div class="grid-wrapper">
      <igx-grid class="grid-strech" igxPreventDocumentScroll #grid [data]="searchedCases" rowSelection="single"
        [autoGenerate]="false" [height]="'100%'" [allowFiltering]="true" [hideRowSelectors]="true"
        (rowSelectionChanging)="onCaseSelection($event)" [style.--ig-size]="'var(--ig-size-small)'">
        <igx-column field="ContactNumber" header="Kontaktnr." [sortable]="true" [dataType]="'string'" width="90px"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="ContactName" header="Navn" [sortable]="true" [dataType]="'string'"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="RoleCode" header="Rolle" [sortable]="true" [dataType]="'string'" width="60px"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="CaseClient" header="Klient" [sortable]="true" [dataType]="'string'"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="CaseAdversary" header="Motpart" [sortable]="true" [dataType]="'string'"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="Title" header="Title" [sortable]="true" [dataType]="'string'"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="CaseStatusTypeName" header="S" [sortable]="true" [dataType]="'string'" width="60px"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="CaseResponsible" header="SA" [sortable]="true" [dataType]="'string'" width="60px"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <igx-column field="CaseHandler" header="SB" [sortable]="true" [dataType]="'string'" width="60px"
          [filterCellTemplate]="defaultFilterTemplate"></igx-column>
        <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
          <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
        </ng-template>
      </igx-grid>
    </div>
  </div>
</div>


<div #messagePopup [hidden]="true" class="popup" *ngIf="selectedMessage">
  <div class="popup-wrapper">
    <i class="material-icons " (click)="popUpClose('selectedMessage')">close</i>
    <div class="form-wrapper">

      <igx-input-group class="msg-type" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
        <label igxLabel for="selectedMessage.Type">Type</label>
        <input #input igxInput name="selectedMessage.Type" autocomplete="off" type="text"
          [(ngModel)]="selectedMessage.Type" [readOnly]="true" />
      </igx-input-group>
      <igx-input-group class="msg-procedure" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
        <label igxLabel for="selectedMessage.Procedure">Prosedyr</label>
        <input #input igxInput name="selectedMessage.Procedure" autocomplete="off" type="text"
          [(ngModel)]="selectedMessage.Procedure" [readOnly]="true" />
      </igx-input-group>
      <igx-input-group class="msg-user" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
        <label igxLabel for="selectedMessage.Username">Brukernavn</label>
        <input #input igxInput name="selectedMessage.Username" autocomplete="off" type="text"
          [(ngModel)]="selectedMessage.Username" [readOnly]="true" />
      </igx-input-group>

      <igx-input-group class="msg-message" [type]="theme" [style.--ig-size]="'var(--ig-size-small)'">
        <label igxLabel for="selectedMessage.Description">Melding</label>
        <textarea oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' #input igxInput
          name="selectedMessage.Description" autocomplete="off" type="text" [(ngModel)]="selectedMessage.Description"
          [readOnly]="true"></textarea>
      </igx-input-group>
    </div>
  </div>
</div>